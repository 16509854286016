import React from "react"
import { graphql, PageProps } from "gatsby"

import Layout from "../components/layout"
import { Form, Description } from "../components/contact"
import { ContactQuery } from "./__generated__/ContactQuery"

export default ({data, location}: PageProps<ContactQuery>) => {

    const api_url = data.site.siteMetadata.contact.api_url;
    const hasContactForm = (api_url);
    return (
        <Layout
            seo={{
                title: "Contact",
            }}
            location={location}
        >
            <div className="container mx-auto py-12">
                <div className="title py-12 text-center">
                    <h2><img src="/images/h_contact.png" alt="Contact" className="image-center" /></h2>
                </div>
                <div className="flex flex-wrap pb-10 image-center">
                    <iframe src="https://docs.google.com/forms/d/e/1FAIpQLSdq24r7e6QptaoDCs2WKO1EWs5a17ZuOG83D7-osgKFaHiURw/viewform?embedded=true" width="100%" height="800" frameborder="0" marginheight="0" marginwidth="0">読み込んでいます…</iframe>
                </div>
            </div>
        </Layout>
    )
}

export const query = graphql`
    query ContactQuery {
        site {
            siteMetadata {
                contact {
                    api_url
                    description
                    mail
                    phone
                    address
                }
            }
        }
    }
`
